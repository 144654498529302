/*遮挡部分CSS*/
.graybg {
  height: 100%;
  width: 100%;
  /* 页面定位到最上面*/
  position: absolute;
  top: 0;
  left: 0;
  background: silver;
  /* 透明度这样能看到后面的内容效果真实一些*/
  opacity: 0.5;
  /* 遮挡级别最好高一些，防止别的内容会突然出现在你的弹出层上面，这就尴尬了。*/
  z-index: 99;
}
/* 弹出框内容CSS*/
.ttform {
  display: block;
  width: 350px;
  height: 580px;
  background: #fff;
  border: 1px solid #000;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.ttform .title {
  display: block;
  width: 100%;
  height: 35px;
  background-color: #ddd;
  padding: 4px;
  font-size: 18px;
  box-sizing: border-box;
  font-weight: bold;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
}

.ttform .close {
  float: right;
  height: 20px;
  width: 20px;
  /*border-radius: 50%;*/
  /* border: 2px solid #000;*/
  text-align: center;
  /*margin-top: -19px; */
  /*margin-right: -15px; */
}

.ttform .content {
  padding: 4px;
  text-align: left;
  background-color: #fff;
  min-height: 130px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
}

.ttform .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
}

.ttform .textbox{
    display: block;
    font-size: 16px;
}

.ttform .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30px;
  /*设置文字垂直居中*/
  line-height: 30px;
  background-color:#ddd;
  border: 1px solid #000;
  border-radius: 0;
}

.ttform .imagepanel{
    width: 100%;
    min-height: 130px;
}